@import './Configs/Styles/reset.scss';
@import './Configs/Styles/font-face.scss';
@import './Components/Wrapper/Wrapper.scss';
@import './Pages/Dashboard/dashboard.scss';

:root {
  --wrapper-max-width: 1280px;
  --section-spacing-desktop: 40px;
  --inner-spacing-small: 16px;
  --inner-spacing-medium: 24px;
  --small-margin: 16px;
  --dashboard-max-width: 1024px;
}
