@import '../../Configs/Styles/variables.scss';

@media (min-width: $desktop-max-width) {
  .overlay-active {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 996;
    overflow: hidden;
    cursor: pointer;
  }
}

.overflow-body {
  overflow-y: hidden;
}

div.wrapper {
  padding: 0 20px;
}

div.container {
  max-width: var(--wrapper-max-width);
  margin: 0 auto;
}

/* stylings */
div.search-section {
  background-color: #c9d2d5;
  border-bottom: 1px solid #c0c8c9;
}
