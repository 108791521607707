@import './SearchForm/searchForm.scss';

.search-container {
  padding: var(--section-spacing-desktop) 0;
  .search-tabs {
    padding: 24px 24px 0 24px;
    background-color: #fff;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #c8c6c3;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.04);
  }
  .heading {
    h1 {
      margin-bottom: 21px;
    }
  }
}

.search-section {
  padding: 5px 0;
  .wrapper:first-child {
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.04);
  }
}
