@import '../../../../Configs/Styles/variables';

.search-container {
  form {
    padding: 24px 24px 20px 24px;
    background-color: #ffffff;
    display: flex;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.04);
    > div.location {
      max-width: 392px;
      flex-direction: column;
      position: relative;
      .results {
        border-radius: 3px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #d6e2e4;
        background-color: #ffffff;
        position: absolute;
        top: 81px;
        background: #fff;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
        border: solid 1px #d6e2e4;
        padding: 12px 0;
        z-index: 999;
        > div {
          p {
            padding: 14px 12px 13px 12px;
            font-size: 18px;
            color: #000000;
            line-height: 1.33;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              background-color: #f2f3f5;
            }
          }
          span {
            padding: 14px 12px 13px 12px;
            display: inline-block;
            color: #878481;
            font-size: 11px;
            line-height: 1.18;
            font-weight: 600;
          }
        }
      }
    }
    > div.date-time {
      max-width: 600px;
      .duration-input-field {
        .teams-select__control {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }

    > div.attendee {
      max-width: 176px;
    }
    > div {
      display: flex;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    > div:not(:last-child) {
      margin-right: 32px;
    }
  }
}

@media (hover: hover) {
  .search-container {
    .teams-select__control:hover {
      background-color: #f2f3f5;
    }
  }
}

@media (max-width: $desktop-max-width) {
  .search-container {
    form {
      flex-wrap: wrap;
      > div.location,
      > div.date-time {
        max-width: unset;
        width: 100%;
        margin-bottom: 32px;
        margin-right: unset;
        flex: auto;
      }
      > div.attendee {
        max-width: 223px;
      }
    }
  }
}

@media (max-width: $mobile-small-max-width) {
  .date-time {
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
    > div:last-child {
      margin-top: 32px;
    }
    div {
      .teams-select__control {
        border-radius: 3px;
      }
    }
  }
}

@media (max-width: $mobile-mid-width) {
  .search-container {
    form {
      > div.item {
        margin-bottom: 16px;
      }
      div.date-time {
        > div:not(:last-child) {
          margin-bottom: 16px;
          flex: auto;
        }
        > div:last-child {
          margin-top: 0;
        }
      }
      div.attendee {
        max-width: 184px;
      }
    }
  }
}
